<template>
  <el-card shadow="never">
    <key-display-dialog :visible.sync="keyVisible"
      :api-key="generatedKey.apiKey"
      :api-secret="generatedKey.apiSecret" />
    <key-history-dialog :visible.sync="historyVisible"
      :api-key="historyApiKey" />

    <h4 class="mb-2">
      {{ $t('api-keys.active-keys') }}
    </h4>

    <el-form ref="apiKeyEditForm"
      v-loading="showLoadingSpinner"
      :model="formData"
      :rules="formRules"
      size="mini"
      :element-loading-text="loadingText"
      @submit.native.prevent>
      <el-table v-if="usersReady"
        :data="keysData"
        :empty-text="$t('api-keys.no-active-keys')"
        max-height="650"
        data-testid="api-keys-table">
        <!-- Name -->
        <el-table-column prop="name"
          :label="$t('general.name')"
          min-width="200">
          <template #default="{row, $index}">
            <div class="flex-row-centered">
              <el-tooltip placement="top"
                :content="row.active ? $t('api-keys.key-active-tooltip') : $t('api-keys.key-inactive-tooltip')"
                :open-delay="500">
                <div class="status-icon"
                  :class="row.active ? 'active' : 'inactive'" />
              </el-tooltip>
              <el-form-item v-if="$index === editingRow"
                prop="name"
                class="keys-form-item">
                <el-input v-model="formData.name"
                  data-testid="name" />
              </el-form-item>
              <template v-else>
                {{ row.name }}
              </template>
            </div>
          </template>
        </el-table-column>
        <!-- Role -->
        <el-table-column prop="role"
          :label="$t('api-keys.role')"
          width="155" />
        <!-- API key -->
        <el-table-column prop="apiKey"
          width="330">
          <template #header>
            {{ $t('api-keys.key') }}
            <el-tooltip placement="top"
              :open-delay="500">
              <template #content>
                <div class="tooltip-p">
                  <p>{{ $t('api-keys.active-tooltip') }}</p>
                </div>
              </template>
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <template #default="{row}">
            <div class="flex-row-centered justify-content-space-between">
              <div>{{ row.apiKey }}</div>
              <el-button type="primary"
                plain
                class="ml-1"
                size="mini"
                @click="copyToClipboard(row.apiKey)">
                {{ $t('general.copy') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <!-- Added by -->
        <el-table-column prop="addedBy"
          :label="$t('api-keys.added-by')"
          min-width="140"
          :formatter="addedByFormatter" />
        <!-- API resource -->
        <el-table-column prop="apiResource"
          :label="$t('api-keys.api-resource')"
          min-width="240">
          <template #default="{row}">
            <a :href="row.apiResource"
              target="_blank">
              {{ row.apiResource }}
            </a>
          </template>
        </el-table-column>
        <!-- Date created -->
        <el-table-column prop="dateCreated"
          :label="$t('api-keys.date-created')"
          min-width="110" />
        <!-- Actions -->
        <el-table-column :label="$t('general.actions')"
          fixed="right"
          min-width="110"
          header-align="center"
          align="center">
          <template #default="{ $index }">
            <div v-if="editingRow === $index">
              <el-button :disabled="!keyEdited"
                type="primary"
                plain
                size="mini"
                data-testid="save"
                @click="saveEdit">
                {{ $t('general.save') }}
              </el-button>
              <el-button class="btn-cancel"
                plain
                size="mini"
                data-testid="cancel"
                @click="cancelEdit">
                <i class="fas fa-times" />
              </el-button>
            </div>
            <el-dropdown v-else-if="editingRow === -1"
              trigger="click"
              @command="action => performAction(action, $index)"
              @visible-change="visible => dropdownVisibleChanged(visible, $index)">
              <el-button plain
                size="mini"
                data-testid="action">
                <i class="fas fa-ellipsis-h" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="action in dropdownActions"
                    :key="action.command"
                    :command="action.command">
                    {{ action.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </el-card>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { roleOptions, sanitiseRole } from '@/components/api-keys/ApiKeyUtils.js'
import KeyAndSecretDialog from '@/components/api-keys/KeyAndSecretDialog.vue'
import KeyHistoryDialog from '@/components/api-keys/KeyHistoryDialog.vue'
import { copyToClipboard } from '@/helpers.js'
import sdk from '@megaport/api-sdk'

const DROPDOWN_ACTION_EDIT = 'edit'
const DROPDOWN_ACTION_DELETE = 'delete'
const DROPDOWN_ACTION_HISTORY = 'history'

export default {
  name: 'ActiveApiKeys',

  components: {
    'key-display-dialog': KeyAndSecretDialog,
    'key-history-dialog': KeyHistoryDialog,
  },

  data() {
    return {
      keysData: [],
      loadingApiKeys: false,
      savingApiKey: false,
      deletingApiKey: false,
      regeneratingSecret: false,
      editingRow: -1,
      formData: {
        name: null,
      },
      formRules: {
        name: { required: true, min: 3, max: 90, pattern: /^[\w -]{3,90}$/, message: this.$t('api-keys.name-invalid', { thing: 'Name' }), trigger: 'blur' },
      },
      displayedDropdown: -1,
      keyVisible: false,
      generatedKey: {
        apiKey: '',
        apiSecret: '',
      },
      historyVisible: false,
      historyApiKey: '',
    }
  },

  computed: {
    ...mapState({
      usersReady: state => state.Users.usersReady,
    }),
    ...mapGetters('Users', ['getUserByPersonUid']),
    ...mapGetters('Auth', ['isLoggedInAs']),
    showLoadingSpinner() {
      return this.loadingApiKeys ||
        this.savingApiKey ||
        this.deletingApiKey ||
        this.regeneratingSecret ||
        !this.usersReady
    },
    loadingText() {
      if (!this.usersReady) {
        return this.$t('api-keys.loading-user-data')
      }
      if (this.loadingApiKeys) {
        return this.$t('api-keys.loading-api-keys')
      }
      if (this.deletingApiKey) {
        return this.$t('api-keys.deleting-api-keys')
      }
      if (this.regeneratingSecret) {
        return this.$t('api-keys.regenerating-secret')
      }
      return this.$t('api-keys.saving-changes')
    },
    roleOptions() {
      return roleOptions(this)
    },
    /**
     * Return whether an API key has been edited or not
     */
    keyEdited() {
      if (this.editingRow === -1) return false
      const keyData = this.keysData[this.editingRow]
      return keyData.name !== this.formData.name
    },
    dropdownActions() {
      const actions = []
      if (!this.isLoggedInAs) {
        actions.push({
          command: DROPDOWN_ACTION_EDIT,
          label: this.$t('general.edit'),
        })
        actions.push({
          command: DROPDOWN_ACTION_DELETE,
          label: this.$t('general.delete'),
        })
      }
      actions.push({
        command: DROPDOWN_ACTION_HISTORY,
        label: this.$t('api-keys.view-history'),
      })

      return actions
    },
  },

  mounted() {
    this.loadApiKeys()
    this.setupReloadListener()
  },

  beforeDestroy() {
    this.tearDownReloadListener()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    copyToClipboard,
    /**
     * Add a listener for reload events
     */
    setupReloadListener() {
      this.$root.$on('reloadApiKeys', () => {
        this.loadApiKeys()
      })
    },

    /**
     * Remove the listener before destroy.
     */
    tearDownReloadListener() {
      this.$root.$off('reloadApiKeys')
    },

    /**
     * Data source for the table, retrieved from the API
     */
    loadApiKeys() {
      this.loadingApiKeys = true
      sdk.instance.apiKeys().getKeys()
        .then(response => {
          this.keysData = response.map(item => {
            return {
              active: item.active,
              name: item.name,
              role: sanitiseRole(this, item.role),
              rawRole: item.role,
              apiKey: item.clientId,
              apiResource: item.resourceServerUrl,
              addedBy: item.createdBy,
              dateCreated: DateTime.fromMillis(Date.parse(item.createDate)).toFormat('LLL dd, y'),
            }
          })
        })
        .catch(error => {
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('api-keys.unable-to-read'),
            message: errorStr,
          })
          if (!error.data?.message) {
            captureSentryError(error)
          }
        })
        .finally(() => {
          this.loadingApiKeys = false
        })
    },

    /**
     * We do this in the formatter rather than the data itself since we are not guaranteed
     * to have the user data loaded until the table is displayed, but this way, we are sure
     * that the user data is available even after a reload of the page.
     * @param {Object} row Table row data
     * @param {Object} column Table column data
     * @param {string} cellValue Value from table cell
     */
    addedByFormatter(_row, _column, cellValue) {
      return this.getUserName(cellValue)
    },

    /**
     * Retrieves the user name by looking them up in the employment data for the company
     * by personUid.
     * @param {string} userUid
     */
    getUserName(userUid) {
      const user = this.getUserByPersonUid(userUid)
      return user ? user.name : this.$t('api-keys.unknown-user')
    },

    /**
     * Hook into the dropdown event so we can animate the turning of the open/close
     * chevron.
     * @param {boolean} visible Visibility
     * @param {number} index Table row index
     */
    dropdownVisibleChanged(visible, index) {
      if (visible) {
        this.displayedDropdown = index
      } else {
        this.displayedDropdown = -1
      }
    },

    /**
     * Called from the dropdown on each row of the table.
     * @param {string} action The action to perform
     * @param {number} index Table row index
     */
    performAction(action, index) {
      switch (action) {
        case DROPDOWN_ACTION_EDIT:
          this.editRow(index)
          return
        case DROPDOWN_ACTION_DELETE:
          this.deleteRow(index)
          return
        case DROPDOWN_ACTION_HISTORY:
          this.showHistory(index)
          return
        default:
          captureSentryError(`Action ${action} is not valid`)
      }
    },

    /**
     * Action to start editing the specified row.
     * @param {number} index Table row index
     */
    editRow(index) {
      this.formData.name = this.keysData[index].name
      this.editingRow = index
    },

    /**
     * Action to cancel editing a row you have started editing
     */
    cancelEdit() {
      this.editingRow = -1
    },

    /**
     * Send the edited name and role to the API to save the updated info.
     */
    saveEdit() {
      this.$refs.apiKeyEditForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }
        const keyData = this.keysData[this.editingRow]
        this.saveKeyUpdate(keyData, this.formData.name)
      })
    },

    /**
     * Internal method to send the updated data to the API and confirm to user.
     * @param {Object} keyData Table row data
     * @param {string} name API key name
     */
    saveKeyUpdate(keyData, name) {
      this.savingApiKey = true

      sdk.instance.apiKeys().updateKey(keyData.apiKey, { name })
        .then(response => {
          this.notifyGood({
            title: this.$t('api-keys.success-update'),
            message: this.$t('api-keys.key-updated-message', { key: response.clientId }),
          })
          keyData.name = name
          this.editingRow = -1
        })
        .catch(error => {
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('api-keys.failed-update'),
            message: errorStr,
          })
          if (!error.data?.message) {
            captureSentryError(error)
          }
        })
        .finally(() => {
          this.savingApiKey = false
        })
    },

    /**
     * Tell the API to delete the row.
     * @param {number} index Table row index
     */
    deleteRow(index) {
      this.$confirm(this.$t('api-keys.delete-key'), this.$t('api-keys.delete-key-label'), {
        confirmButtonText: this.$t('api-keys.delete-key-label'),
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
      })
        .then(() => {
          this.deleteKeyAtIndex(index)
        })
        .catch(() => {
          // empty function is intentional
        })
    },

    /**
     * Internal method to get the API to delete the key and confirm to the user.
     * @param {number} index Table row index
     */
    deleteKeyAtIndex(index) {
      const keyData = this.keysData[index]
      this.deletingApiKey = true
      sdk.instance.apiKeys().deleteKey(keyData.apiKey)
        .then(() => {
          this.notifyGood({
            title: this.$t('api-keys.success-delete'),
            message: this.$t('api-keys.key-deleted-message', { key: keyData.apiKey }),
          })
          this.keysData.splice(index, 1)
        })
        .catch(error => {
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('api-keys.failed-delete'),
            message: errorStr,
          })
          if (!error.data?.message) {
            captureSentryError(error)
          }
        })
        .finally(() => {
          this.deletingApiKey = false
        })
    },

    /**
     * Show the history dialog - it knows how to fetch its own data
     * @param {number} index Table row index
     */
    showHistory(index) {
      this.historyApiKey = this.keysData[index].apiKey
      this.historyVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.el-icon-arrow-down {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
}

// Action column buttons
.el-button + .el-button {
  margin-left: 5px;
}

// Editing name field
.keys-form-item {
  margin: 4px 0;
}

.btn-cancel {
  padding: 7px;
}

.el-table {
  width: 100%;
}
.status-icon {
  border-radius: 50%;
  background-color: var(--color-text-regular);
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
  &.active {
    background-color: var(--color-success);
  }
  &.inactive {
    background-color: var(--color-danger);
  }
}
</style>
